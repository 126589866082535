import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Amplify, { Auth } from 'aws-amplify';
import store from 'store';
import { navigate, Link } from 'gatsby'
import amplifyConfig from '../amplify';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { USER_ROLES } from '../constants';
import Layout from '../components/layout';
import useFormInput from '../components/use-form-input';
import useLoginStyles from '../components/use-login-styles';

const config = amplifyConfig();
Amplify.configure(config);

function LoginPage({path}) {
  const { t } = useTranslation('login');
  const classes = useLoginStyles();
  const [storedCognitoUser, setStoredCognitoUser] = useState();
  const username = useFormInput();
  const password = useFormInput();
  const newPassword = useFormInput();
  const confirmPassword = useFormInput();
  const [form, setForm] = useState({ isSending: false, isCompletePassword: false, isSuccess: false, error: null }); 
  const isInvalidForm = !username.isValid || !password.isValid;
  const defaultError = "An error occured, please try again. If the error persists, contact the administrator.";
  const handleOnLogin = async (e) => {
    e.preventDefault();
    store.set('settings', {});
    setForm({ ...form, isSending: true });
    try {
      const cognitoUser = await Auth.signIn(username.value, password.value);
      setStoredCognitoUser(cognitoUser);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setForm({...form, isSending: false, error: null, isCompletePassword: true });
      } else {
        const attributes = cognitoUser.attributes
          || (cognitoUser.challengeParam && cognitoUser.challengeParam.userAttributes);
        const role = attributes && attributes['custom:role'];
        if (role === USER_ROLES.SYSTEM_ADMIN) {
          navigate('/select-tenant');
        } else {
          navigate('/select-store');
        }
      }
    } catch(error) {
      const message = typeof error === 'string' ? error : (error.message || defaultError);
      setForm({ ...form, isSending: false, isError: true, isSuccess: false, error: message });
    }
  }
  const handleOnCompletePassword = async (e) => {
    e.preventDefault();
    if (newPassword.value !== confirmPassword.value) return setForm({...form, error: 'Passwords do not match'});
    setForm({ ...form, isSending: true });
    try {
      await Auth.completeNewPassword(storedCognitoUser, newPassword.value);
      const attributes = storedCognitoUser.attributes
        || (storedCognitoUser.challengeParam && storedCognitoUser.challengeParam.userAttributes);
      const role = attributes && attributes['custom:role'];
      if (role === USER_ROLES.SYSTEM_ADMIN) {
        navigate('/select-tenant');
      } else {
        navigate('/select-store');
      }
    } catch(error) {
      const message = typeof error === 'string' ? error : (error.message || defaultError);
      setForm({ ...form, isSending: false, isSuccess: false, error: message });
    }
  }
  const cta = form.isCompletePassword ? t('ctaCompletePassword') : t('cta');
  return (
    <Layout path={path}>
      <Container component="main" maxWidth="xs">
        { form.error && <Alert severity="error">{form.error}</Alert> }
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            { form.isCompletePassword ? t('completePassword') : t('logIn') }
          </Typography>
          <form className={classes.form} noValidate>
          { !form.isCompletePassword && (
              <React.Fragment>
                 <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  autoFocus
                  fullWidth
                  id="username"
                  label={t('username')}
                  margin="normal"
                  name="username"
                  required
                  type="text"
                  variant="outlined"
                  value={username.value}
                  onChange={username.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="password"
                  label={t('password')}
                  margin="normal"
                  name="password"
                  required
                  type="password"
                  variant="outlined"
                  value={password.value}
                  onChange={password.onChange}
                />
             </React.Fragment>
            )}
            { form.isCompletePassword && (
              <React.Fragment>
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="newPassword"
                  label="New password"
                  margin="normal"
                  name="newPassword"
                  required
                  type="password"
                  variant="outlined"
                  value={newPassword.value}
                  onChange={newPassword.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="confirmPassword"
                  label="Confirm password"
                  margin="normal"
                  name="confirmPassword"
                  required
                  type="password"
                  variant="outlined"
                  value={confirmPassword.value}
                  onChange={confirmPassword.onChange}
                />
              </React.Fragment>
            )}
            <div></div>
            <Button
              disabled={form.isSending || isInvalidForm}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={form.isCompletePassword ? handleOnCompletePassword : handleOnLogin }
            >
              { form.isSending ? t('sending') : cta }
            </Button>
            { !form.isCompletePassword && <Link className={classes.link} to="/forgot-password">{t('iForgotPassword')}</Link> }
          </form>
        </div>
      </Container>
    </Layout>
  );
}

export default LoginPage;
